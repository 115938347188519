<template>
    <nav role="navigation" class="menu-container">
        <div class="menu">
            <div class="left">
                <router-link to="/profile" class="menu-link">
                    <CogSVG />
                    <span class="desktop">
                        Account Settings
                    </span>
                    <span class="mobile">
                        Settings
                    </span>
                </router-link>
                <router-link to="/account" class="menu-link">
                    <HouseSVG />
                    <span>
                        Home
                    </span>
                </router-link>
            </div>
            <div class="right">
                <div class="menu-link" @click="logoutShow = true">
                    <span>
                        Log out
                    </span>
                    <ExitSVG />
                </div>
            </div>
        </div>
        <Dialog modal :visible.sync="logoutShow" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    Warning
                </h1>
                <Divider />
                <p>
                    Are you sure you want to log out?
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="logoutShow = false">
                        Cancel
                    </Button>
                    <Button class="btn orange-bg" @click="logout">
                        Continue
                    </Button>
                </div>
            </div>
        </Dialog>
    </nav>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import CogSVG from '../../public/images/icons/cog.svg';
import HouseSVG from '../../public/images/icons/house.svg';
import ExitSVG from '../../public/images/icons/exit.svg';

export default {
    name: 'AlertMenu',
    components:{
        Dialog,
        Button,
        Divider,
        CogSVG,
        HouseSVG,
        ExitSVG,
    },
    data(){
        return {
            logoutShow: false,
        }
    },
    methods: {
        logout(){
            this.logoutShow = false;
            this.$store.dispatch('logout');
            this.$router.push('/');
        },
    },
}
</script>

<style lang="scss" scoped>
.menu-container{
    padding: 10px 25px;
    background: #F5F5F5;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.menu{
    display: flex;
    justify-content: space-between;
}

.menu-link{
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-family: "gravesend-sans", sans-serif;
    letter-spacing: 5%;
    &:hover{
        cursor: pointer;
        color: #EF6A40;
    }
    &.router-link-exact-active{
        color:#EF6A40;
        svg{
            fill: #EF6A40;
        }
    }
}

.left, .right{
    display: flex;
    gap: 20px;
}

@media (max-width: $mobile) {
    .services{
        margin: 79px 0;
    }

    .left, .right{
        gap: 16px;
    }
}

</style>
