<template>
    <div class="edit-alert-container">
        <div class="title-container">
            <div v-if="isCreate">
                <h1 class="title alert-page-title">
                    Create New Alert
                </h1>
            </div>
            <div v-else>
                <h1 class="title alert-page-title">
                    Editing:
                </h1>
                <h1 class="alert-name">
                    {{ alertData.data.Name }}
                </h1>
            </div>
        </div>
        <div class="form-container">
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>Renting or Buying?</label>
                    <multiselect
                        aria-label="Alert Type"
                        id="0"
                        v-model="listingType"
                        track-by="label"
                        label="label"
                        placeholder="Renting or buying"
                        :options="listingOptions"
                        :searchable="false"
                        :showLabels="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="price-range-container">
                <div class="slider-container">
                    <p class="slider-title">
                        Price Range <span class="subtext">(Slide to Adjust)</span>
                    </p>
                    <Slider class="slider" v-model="price" :range="true" :step="step" :min="0" :max="priceMax" @change="changed=true" />
                    <div class="slider-label-container">
                        <label class="slider-label">
                            MIN: <b>{{ price[0] | priceFormat }}</b>
                        </label>
                        <label class="slider-label">
                            MAX: <b>{{ price[1] | priceFormat }}</b> <span v-if="price[1] === priceMax">+</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>City:</label>
                    <multiselect
                        id="1"
                        v-model="city"
                        :showLabels="false"
                        :options="uniqueCities"
                        :searchable="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>Beds:</label>
                    <multiselect
                        aria-label="Beds"
                        id="2"
                        v-model="beds"
                        track-by="label"
                        label="label"
                        :options="bedsOptions"
                        :searchable="false"
                        :showLabels="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>Baths:</label>
                    <multiselect
                        id="3"
                        v-model="baths"
                        track-by="label"
                        label="label"
                        :options="bathsOptions"
                        :searchable="false"
                        :showLabels="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="checkbox-container outer-container">
                <div class="container inner-container">
                    <label>Property Type:</label>
                    <div class="checkboxes">
                        <div v-for="option of propertyOptions" :key="option.key" class="field-checkbox">
                            <Checkbox :id="option.key" name="propertyOption" :value="option" v-model="selectedPropertyOptions" @change="updateCheckbox"/>
                            <label :for="option.key">{{option.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>Pets:</label>
                    <multiselect
                        aria-label="Pets"
                        id="4"
                        v-model="pets"
                        track-by="label"
                        label="label"
                        :options="petsOptions"
                        :searchable="false"
                        :showLabels="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="dropdown-container outer-container">
                <div class="dropdown inner-container">
                    <label>Laundry:</label>
                    <multiselect
                        aria-label="Laundry"
                        id="5"
                        v-model="laundry"
                        track-by="label"
                        label="label"
                        :options="laundryOptions"
                        :searchable="false"
                        :showLabels="false"
                        :allow-empty="false"
                        @select="changed=true"
                        :showNoResults="false">
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div class="input-container outer-container">
                <div class="inner-container name-section">
                    <label>Name Your Alert*:</label>
                    <div class="input">
                        <InputText
                            v-model="alertName"
                            id="alertName"
                            type="text"
                            aria-describedby="alertname-help"
                            :class="{'p-invalid' : nameError}"
                            @input="nameError = false;changed=true;"
                        />
                        <small v-if="nameError" id="alertname-help" class="p-invalid">Please fill in the name of your alert.</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-container">
            <Button class="btn black-bg" @click="exit">
                Exit 
                <img src="/images/icons/cross.svg" alt="Exit">
            </Button>
            <Button class="btn orange-bg" v-if="isCreate" @click="createUpdateAlert">
                Enable Alert
                <img src="/images/icons/save.svg" alt="Enable Alert">
            </Button>
            <Button class="btn orange-bg" v-else @click="createUpdateAlert" :disabled="!changed">
                Save Changes
                <img src="/images/icons/save.svg" alt="Save changes">
            </Button>
        </div>
        <Divider class="divider" v-if="isCreate" />
        <div class="instruction" v-if="isCreate">
            <p>
                Click 'Enable Alert' to begin receiving real-time notifications whenever a property that fits your criteria becomes available on our website.
            </p>
        </div>
        <Dialog modal :visible.sync="showWarningModal" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    UNSAVED CHANGES
                </h1>
                <Divider />
                <p class="modal-content">
                    Exiting now will discard any changes made to your alert settings. Please save your changes to ensure your alert preferences are updated.
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="$router.push('/account')">
                        Exit Anyway
                    </Button>
                    <Button class="btn orange-bg" @click="showWarningModal = false">
                        Continue Editing
                    </Button>
                </div>
            </div>
        </Dialog>
        <Toast />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import { apiURL } from '../util.js'
import Slider from 'primevue/slider';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog'
import Toast from 'primevue/toast';

export default {
    name: "CreateEditAlert",
    components: {
        Multiselect,
        Slider,
        InputText,
        Checkbox,
        Button,
        Divider,
        Dialog,
        Toast
    },
    props:{
        isCreate: Boolean,
        alertData: Object,
    },
    data(){
        return{
            apiURL,
            alertName: '',
            changed: false,
            showWarningModal: false,
            listingType: { label: "Renting", value: "renting" },
            listingOptions:[
                { label: "Renting", value: "renting" },
                { label: "Buying", value: "buying" },
            ],
            beds: { label: "Any", value: "-1" },
            bedsOptions:[
                { label: "Any", value: "-1" },
                { label: "N/A", value: "0" },
                { label: "Studio", value: "0.5" },
                { label: "1 Bedroom", value: "1" },
                { label: "2 Bedrooms", value: "2" },
                { label: "3 Bedrooms", value: "3" },
                { label: "4+ Bedrooms", value: "4" },
            ],
            baths: { label: "Any", value: "-1" },
            bathsOptions:[
                { label: "Any", value: "-1" },
                { label: "N/A", value: "0" },
                { label: "0.5 Bathroom", value: "0.5" },
                { label: "1 Bathroom", value: "1" },
                { label: "1.5 Bathrooms", value: "1.5" },
                { label: "2 Bathrooms", value: "2" },
                { label: "2.5 Bathrooms", value: "2.5" },
                { label: "3 Bathrooms", value: "3" },
                { label: "3.5 Bathrooms", value: "3.5" },
                { label: "4+ Bathrooms", value: "4" },
            ],
            pets: { label: "Any", value: "any" },
            petsOptions:[
                { label: "Any", value: "any" },
                { label: "N/A", value: 'na' },
                { label: "Yes/Negotiable", value: "yes" },
                { label: "No", value: 'no' },
            ],
            laundry: { label: "Any", value: "any" },
            laundryOptions:[
                { label: "Any", value: "any" },
                { label: "N/A", value: 'na' },
                { label: "In Unit", value: "unit" },
                { label: "Shared Laundry", value: "shared" },
                { label: "None", value: 'none' },
            ],
            selectedPropertyOptions: [{ name: 'Any', key: 'any'}],
            propertyOptions: [
                { name: 'Any', key: 'any'},
                { name: 'HOUSE / COTTAGE', key: 'house'},
                { name: 'TOWNHOUSE', key: 'town'},
                { name: 'CONDO / APARTMENT UNIT', key: 'condo'},
                { name: 'MULTIPLE UNITS', key: 'multi'},
                { name: 'COMMERCIAL', key: 'commercial'},
            ],
            city: 'Any',
            price: [10000,40000],
            nameError: false,
        }
    },
    async mounted(){
        if(!this.isCreate){
            this.alertName = this.alertData.data.Name;
            this.listingType = this.listingOptions.find(item => item.value === this.alertData.data.AlertType);
            this.beds = this.bedsOptions.find(item => Number(item.value) === this.alertData.data.Bedrooms);
            this.baths = this.bathsOptions.find(item => Number(item.value) === this.alertData.data.Bathrooms);
            this.pets = this.petsOptions.find(item => item.value === this.alertData.data.Pet);
            this.laundry = this.laundryOptions.find(item => item.value === this.alertData.data.Laundry);
            this.city = this.alertData.data.City;
            this.price = [this.alertData.data.PriceMin, Number(this.alertData.data.PriceMax)];
            this.selectedPropertyOptions = this.alertData.data.PropertyType.map((item) => {
                return this.propertyOptions.find(option => option.key === item);
            });
        }
    },
    computed:{
        uniqueCities: function(){
            let a = this.$store.getters.properties.map(p => p.City.trim()).filter((a, i, l) => l.indexOf(a) === i).sort();
            a.unshift("Any");
            return a;
        },
        step(){
            if(this.listingType.value === 'renting'){
                return 100;
            } else {
                return 25000;
            }
        },
        priceMax(){
            if(this.listingType.value === 'renting'){
                return 50000;
            } else {
                return 5000000;
            }
        },
        dataToBeSubmitted(){
            const data = {
                Name: this.alertName,
                AlertType: this.listingType.value,
                PriceMin: this.price[0],
                PriceMax: this.price[1],
                City: this.city,
                Bedrooms: Number(this.beds.value),
                Bathrooms: Number(this.baths.value),
                PropertyType: this.selectedPropertyOptions.map(item => item.key),
                Pet: this.pets.value,
                Laundry: this.laundry.value,
            };
            return data;
        },
    },
    watch:{
        listingType (newVal, oldVal){
            if(oldVal.value !== newVal.value){
                if(newVal.value === 'buying'){
                    this.price = [1000000, 4000000];
                }else{
                    this.price = [10000, 40000];
                }
            }
        },
        changed(newVal){
            this.$emit("alertChanged", newVal);
        }
    },
    methods: {
        async createUpdateAlert(){
            if(!this.alertName){
                this.nameError = true;
                return;
            }
            if(!this.changed){
                return;
            }
            try {
                const header = this.$store.getters.authHeader;
                const url = this.isCreate ? `${apiURL}/api/alert-manage` : `${apiURL}/api/alert-manage/${this.alertData.data.id}`
                if(this.isCreate){
                    await this.axios.post(url,this.dataToBeSubmitted,header);
                } else {
                    await this.axios.put(url,this.dataToBeSubmitted,header);
                }
                this.changed = false;
                this.$toast.add({severity:'success', summary: this.isCreate ? 'Alert created' : 'Alert updated', detail: this.isCreate ? 'You alert has been created.' : 'Your alert has been updated.', life: 3000});
                setTimeout(() => {
                    if(this.isCreate){
                        this.$router.push('/account');
                    }
                }, 0);
            } catch(e) {
                this.error = true;
            }
            return {};
        },
        updateCheckbox(){
            this.changed = true;
            if(this.selectedPropertyOptions.length === 0){
                this.selectedPropertyOptions = [{ name: 'Any', key: 'any'}];
            }
        },
        exit(){
            if(this.changed){
                this.showWarningModal = true;
            }else{
                this.$router.push('/account');
            }
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
h1{
    text-align: center;
}

.edit-alert-container{
    background: #F5F5F5;
}

.outer-container{
    background: #F5F5F5;
    margin: 0;
    padding: 20px 0;
}

.inner-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding-right: 38%;
    label{
        text-align: right;
    }
}

.input-container{
    background: #fff;
    align-items: center;
}

.container{
    align-items: flex-start;
}

.price-range-container{
    display: flex;
    justify-content: center;
    background: #fff;
    .slider-title{
        margin-top: 30px;
        font-weight: 300;
    }
    .slider-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        margin: 0 30%;
    }
    .slider{
        width: 100%;
        margin: 30px 0;
    }
    .slider-label-container{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 20px 0;
    }
    .subtext{
        color: #B9B9B9;
    }
}

.title-container{
    padding: 20px 0;
}

.title{
    margin: 0;
}

.multiselect, .checkboxes, .input{
    width: 360px;
}

.checkboxes{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input{
    display: flex;
    flex-direction: column;
}

.field-checkbox{
    display: flex;
    align-items: center;
    gap: 5px;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 180px;
    background: #F5F5F5;
    margin: 0;
    padding: 40px 0;
}

.divider{
    width: 90%;
    margin: 0 auto;
}
.instruction{
    width: 70%;
    margin: 40px auto;
    padding-bottom: 40px;
}
.alert-name{
    margin-top: 0;
    color: #EF6A40;
}
.name-section{
    margin: 30px auto;
}

@media (max-width: $mobile) {
    .container{
        flex-wrap: wrap;
    }
    .inner-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        padding-right: 15%;
    }
    .multiselect, .input{
        width: 200px;
    }
    .button-container{
        gap: 40px;
    }
    .price-range-container{
        .slider-container{
            width: 80%;
            margin: 30px auto;
        }
    }
    .checkbox-container{
        .inner-container{
            flex-direction: column;
            padding: 0;
        }
        .checkboxes{
            align-self: flex-end;
            width: 300px;
        }
    }
}
</style>